export const menuItemsData = [
  {
    title: "Home",
    url: "/",
  },
  {
    title: "Contest",
    url: "/contest",
  },
  // {
  //   title: "About",
  //   url: "/about",
  // },
  {
    title: "Events",
    url: "/events",
  },

  {
    title: "Club",
    url: "/Club",
    submenu: [
      {
        title: "Bronze",
        url: "bronze",
      },
      {
        title: "Silver",
        url: "Silver",
      },
      {
        title: "Gold",
        url: "Gold",
      },
      {
        title: "Platinum",
        url: "Platinum",
      },
      {
        title: "Diamond",
        url: "Diamond",
      }

    ],
  },
  {
    title: "Achivers",
    url: "/achivers",
  },
  {
    title: "Gallery",
    url: "/gallery",
  },


  // {
  //   title: "Contact",
  //   url: "/contact",
  // },
];
